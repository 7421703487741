/* HomePage.css */
.containerZ {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .containerZ p {
    font-size: 16px;
    line-height: 1.5;
    margin: 10px 0;
  }
  
  .containerZ p b {
    color: #333;
  }
  
  .containerZ button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .containerZ button:hover {
    background-color: #0056b3;
  }
  
  .containerZ br {
    margin-bottom: 10px;
  }
  
  @media (max-width: 600px) {
    .containerZ {
      padding: 15px;
    }
  
    .containerZ p {
      font-size: 14px;
    }
  
    .containerZ button {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  