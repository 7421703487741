

.containerX {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-top: 20px;
    margin-left: auto; 
  }

.App-logo {
    display: flex;
    height: 40px;
    margin-right: 10px;
}

.Form-container {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 10px; /* Adjust margin between form and other content */
}

.Form-container input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
    width: 200px; /* Adjust width as needed */
}

.Form-container button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* .other-content {
    display: flex;
    align-items: center;
} */

.Button {
    color-scheme: dark;
    display: flex;
    align-items: center;
}

.logoCont{
    float: left;

}

.rblock{
  margin-right:auto;
  align-content: center;
    
    background-color: yellowgreen;
}