.main-nav {
    background-color: #f8f9fa;
    padding: 10px;
    text-align: center;
}

.main-nav a {
    color: #888; /* Gray color for inactive links */
    text-decoration: none;
    padding: 10px;
    margin-right: 10px;
  }
  

.main-nav a:hover {
    text-decoration: underline;
    color: #0056b3;
}
