/* Request.css */

/* Increase specificity by targeting elements within .request-form-container */
.request-form-container {
  max-width: 300px;
  margin: 30%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.request-form-container h3 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.request-form-container .form-group {
  margin-bottom: 15px; /* Slightly reduced margin for a more compact look */
}

.request-form-container .form-control {
  height: 38px;
  font-size: 15px;
}

.request-form-container .form-label {
  font-weight: 500; /* Ensure labels stand out */
}

.request-form-container .btn-primary {
  width: 100%;
  height: 38px;
  font-size: 16px;
}

.request-form-container .btn-primary:hover {
  background-color: #007bff;
  border-color: #007bff;
}

/* Ensure .request-form-container has priority */
.request-form-container .mt-4 {
  margin-top: 20px !important;
}

.request-form-container .mt-3 {
  margin-top: 15px !important;
}

.text-center {
  text-align: right;
  /* background-color: rgb(134, 15, 15); */
}


.request-form-container .form-label {
  text-align: left;
}


.request-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.form-label {
  text-align: left !important; /* Ensure labels are aligned to the left */
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.d-none {
  display: none;
}
