/* AddDeal.css */

.add-deal-container {
    background-color: #e0f7fa; /* Light blue background color */
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-deal-container h2 {
    text-align: center;
    color: #007bb5; /* Dark blue color for the heading */
  }
  
  .add-deal-container form {
    display: flex;
    flex-direction: column;
  }
  
  .add-deal-container label {
    margin-bottom: 5px;
    color: #007bb5;
  }
  
  .add-deal-container input,
  .add-deal-container select {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #007bb5;
    border-radius: 4px;
  }
  
  .add-deal-container input[type="checkbox"] {
    width: auto;
    margin-left: 10px;
  }
  
  .add-deal-container button {
    background-color: #007bb5;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-deal-container button:hover {
    background-color: #005f8a;
  }
  
  .notification {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .notification.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  