/* PartsTable.css */
.expandable-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .expandable-text:hover {
    white-space: normal;
  }
  