.App {
  text-align: center;
}

.App-logo {
  margin-right: 2000px;
  height: 5vmin;
  pointer-events: none;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    size: auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div {
  border: 2px solid #052c79;
}


.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: hwb(60 50% 49%);
}

.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-text {
  flex: 1;
}

.card-image {
  margin-left: 20px;
}

.card-image img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 8px;
  object-fit: cover;
}

.nav-itemAWS {
  color: red;
  text-decoration: none; /* Optional: Remove underline */
}