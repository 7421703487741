/* Register.css */

.user-creation-form {
    max-width: 400px; /* Adjust the maximum width as per your design */
    margin: auto; /* Center align the form horizontally */
    padding: 20px;
    background-color: #f8f9fa; /* Light background color */
    border: 1px solid #ccc; /* Border around the form */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle lift */
}

.user-creation-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

.user-creation-form .form-group {
    margin-bottom: 15px;
}

.user-creation-form .form-label {
    font-weight: bold;
}

.user-creation-form .form-control {
    height: 40px;
    font-size: 16px;
    border-radius: 3px; /* Rounded corners for form inputs */
}

.user-creation-form .btn-primary {
    width: 100%; /* Full width button */
    height: 40px;
    font-size: 16px;
    margin-top: 10px; /* Space between buttons */
}

.user-creation-form .btn-primary:hover {
    background-color: #007bff;
    border-color: #007bff;
}

.user-creation-form .alert {
    margin-top: 15px; /* Space above alerts */
}

.user-creation-form .alert-success {
    margin-bottom: 0; /* Remove bottom margin from success alert */
}

.red-button {
    background-color: red !important; /* Use !important to override default Bootstrap styles */
    border-color: red !important; /* Same for border color */
    color: white; /* Optional: Set text color */
}