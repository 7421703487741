.copyright {
    background-color: #f8f9fa;
    color: #495057;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}

.copyright p {
    margin-bottom: 5px;
}

.copyright h5 {
    margin-top: 10px;
    font-size: 12px;
}

/* Styling for the bold text within the paragraph */
.copyright span b {
    font-weight: bold;
    color: #1d2124; /* Blue color for emphasis */
}

/* Styling for the links (assuming they are hyperlinks) */
.copyright a {
    color: #0f1e2d;
    text-decoration: none; /* Remove underline from links */
    margin: 0 5px; /* Add margin to separate links */
}

/* Hover effect for links */
.copyright a:hover {
    text-decoration: underline; /* Underline on hover */
}
